let deferredPrompt;

export const initializePWAInstall = () => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredPrompt = e;
    showInstallPromotion();
  });
};

export const showInstallPromotion = () => {
  const installButton = document.createElement('button');
  installButton.textContent = 'Install App';
  installButton.classList.add('install-button');
  installButton.addEventListener('click', installPWA);
  document.body.appendChild(installButton);
};

export const installPWA = () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      deferredPrompt = null;
    });
  }
};