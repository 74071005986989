import React, { useState, useEffect } from "react";
import axios from "axios";
import { QRCodeCanvas } from "qrcode.react";
import "./App.css";
import { initializePWAInstall } from './pwaInstall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faClock } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

function App() {
  const [currentTime, setCurrentTime] = useState("");
  const [nextBooking, setNextBooking] = useState(null);
  const [error, setError] = useState(null);
  const [timeUntilNextBooking, setTimeUntilNextBooking] = useState(null);
  const [isLunchTime, setIsLunchTime] = useState(false);
  const [showLunchHours, setShowLunchHours] = useState(false);
  const [ongoingBooking, setOngoingBooking] = useState(null);

  // Function to update and show current time
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentTime(now.toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  // Updated function to fetch bookings and handle lunch time
  const fetchBookings = async () => {
    try {
      const response = await axios.get(
        'https://api.cal.com/v1/bookings?apiKey=cal_live_c532273499505039eeda3e59431a818f'
      );
      
      const now = new Date();
      const hours = now.getHours();
      const isLunch = hours >= 13 && hours < 15;
      setIsLunchTime(isLunch);

      if (response.data && Array.isArray(response.data.bookings)) {
        const currentBooking = response.data.bookings.find(booking => 
          booking.status !== 'CANCELLED' && 
          booking.status !== 'REJECTED' &&
          new Date(booking.startTime) <= now &&
          new Date(booking.endTime) > now
        );
        
        setOngoingBooking(currentBooking || null);

        const upcomingBookings = response.data.bookings.filter(booking => 
          booking.status !== 'CANCELLED' && 
          booking.status !== 'REJECTED' &&
          new Date(booking.startTime) > now
        );
        
        const sortedBookings = upcomingBookings.sort((a, b) => 
          new Date(a.startTime) - new Date(b.startTime)
        );
        
        // Check if there are no bookings until 1 PM
        const nextBookingTime = sortedBookings[0] ? new Date(sortedBookings[0].startTime) : null;
        const showLunch = !nextBookingTime || (nextBookingTime.getHours() >= 13 && now.getHours() < 13);
        setShowLunchHours(showLunch);

        if (isLunch && (!sortedBookings[0] || new Date(sortedBookings[0].startTime).getHours() >= 15)) {
          setNextBooking(null);
        } else {
          setNextBooking(sortedBookings[0] || null);
        }
      } else {
        setNextBooking(null);
        setOngoingBooking(null);
        setShowLunchHours(now.getHours() < 13);
      }
    } catch (err) {
      console.error(err);
      setError("Unable to fetch bookings. You may be offline or experiencing connection issues.");
      // Use cached data if available
      const cachedBooking = localStorage.getItem('cachedNextBooking');
      if (cachedBooking) {
        setNextBooking(JSON.parse(cachedBooking));
      }
    }
  };

  // New function to calculate and update time until next booking
  const updateTimeUntilNextBooking = () => {
    if (nextBooking) {
      const now = new Date();
      const bookingStart = new Date(nextBooking.startTime);
      const timeDiff = bookingStart - now;
      
      if (timeDiff > 0) {
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        
        setTimeUntilNextBooking(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeUntilNextBooking("Meeting is starting now!");
      }
    } else {
      setTimeUntilNextBooking(null);
    }
  };

  // Update the useEffect for fetching bookings
  useEffect(() => {
    initializePWAInstall();
    fetchBookings(); // Initial fetch

    const bookingInterval = setInterval(() => {
      console.log("Fetching bookings... Every 5 minutes");
      fetchBookings();
    }, 1 * 60 * 1000); // 5 minutes in milliseconds

    return () => clearInterval(bookingInterval);
  }, []);

  // New useEffect for updating the countdown timer
  useEffect(() => {
    if (nextBooking) {
      updateTimeUntilNextBooking();
      const countdownInterval = setInterval(updateTimeUntilNextBooking, 1000);
      return () => clearInterval(countdownInterval);
    }
  }, [nextBooking]);

  useEffect(() => {
    if (nextBooking) {
      localStorage.setItem('cachedNextBooking', JSON.stringify(nextBooking));
    }
  }, [nextBooking]);

  return (
    <div className="App">
      <Helmet>
        <link href="https://fonts.googleapis.com/css2?family=Michroma&display=swap" rel="stylesheet" />
      </Helmet>

      <h1 className="welcome-message">OFIS India <br/>
      Meeting Room</h1>

      <div className="time-display">
        <h2>Current Time</h2>
        <p className="current-time">{currentTime}</p>
      </div>

      <div className="bookings-container">
        <h2>Current Booking</h2>
        {ongoingBooking ? (
          <div className="booking-item ongoing">
            <div className="booking-details">
              <div className="booking-info">
                <span className="booking-time">
                  <i className="far fa-clock"></i>
                  Started at: {new Date(ongoingBooking.startTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </span>
                <span className="booking-attendee">
                  <i className="far fa-user"></i>
                  Booked By: {ongoingBooking.attendees[0].name}
                </span>
                <span className="booking-end-time">
                  <i className="fas fa-hourglass-end"></i>
                  Ends at: {new Date(ongoingBooking.endTime).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <p className="no-bookings">No ongoing booking</p>
        )}

        <h2>Next Booking</h2>
        {error ? (
          <p className="error-message">{error}</p>
        ) : isLunchTime ? (
          <div className="lunch-time-container">
            <FontAwesomeIcon icon={faUtensils} className="lunch-icon" />
            <div className="lunch-info">
              <p className="lunch-title">Lunch Break</p>
              <p className="lunch-hours">
                <FontAwesomeIcon icon={faClock} /> 13:00 - 15:00
              </p>
            </div>
          </div>
        ) : showLunchHours ? (
          <div className="lunch-time-container upcoming">
            <FontAwesomeIcon icon={faUtensils} className="lunch-icon" />
            <div className="lunch-info">
              <p className="lunch-title">Upcoming Lunch Break</p>
              <p className="lunch-hours">
                <FontAwesomeIcon icon={faClock} /> Starts at 13:00
              </p>
            </div>
          </div>
        ) : nextBooking ? (
          <div className="booking-item">
            <div className="booking-details">
              <div className="booking-info">
                <span className="booking-time">
                  <i className="far fa-clock"></i>
                  {new Date(nextBooking.startTime).toLocaleString()}
                </span>
                <span className="booking-attendee">
                  <i className="far fa-user"></i>
                  Booked By: {nextBooking.attendees[0].name}
                </span>
                {timeUntilNextBooking && (
                  <span className="booking-countdown">
                    <i className="fas fa-hourglass-half"></i>
                    Starts in: {timeUntilNextBooking}
                  </span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <p className="no-bookings">No upcoming bookings</p>
        )}
      </div>

      <div className="qr-code-section">
        <h2>Scan to Book an Appointment</h2>
        <QRCodeCanvas value="https://cal.com/ofisindia" size={150} className="qr-code" />
      </div>
    </div>
  );
}

export default App;
